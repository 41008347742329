import {initializeApp} from 'firebase/app';
import {getStorage} from "firebase/storage";
import {getFirestore} from "firebase/firestore";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDNNn4xOwnSh_iQLybh8_W778B3U5tcXFI",
    authDomain: "spicy-pencil-tattoos-619f4.firebaseapp.com",
    projectId: "spicy-pencil-tattoos-619f4",
    storageBucket: "spicy-pencil-tattoos-619f4.appspot.com",
    messagingSenderId: "1070210544571",
    appId: "1:1070210544571:web:2656043b6f8701705c0a9e",
    measurementId: "G-ZLYVNXRBB3"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
