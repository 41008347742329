import React, {useEffect, useRef, useState} from 'react';
import {storage} from "../../firebase";
import {ref, listAll, getDownloadURL} from 'firebase/storage';

const StorageImages = ({folder, numRows, onLoad, captions}) => {
    const [images, setImages] = useState([]);
    const [visibleImages, setVisibleImages] = useState([]);
    const [numVisible, setNumVisible] = useState(0);
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [showMore, setShowMore] = useState(false);

    const containerRef = useRef(null);
    const buttonRef = useRef(null);
    useEffect(() => {
        const handleResize = () => {
            const containerWidth = containerRef.current.offsetWidth;
            const imageWidth = 256 + 16; // Width of each image
            setNumVisible(Math.floor(containerWidth / imageWidth) * numRows)
        };

        handleResize(); // Call once on initial render
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [numRows]);


    useEffect(() => {
        listAll(ref(storage, folder))
            .then((res) => {
                const promises = res.items.map((itemRef) => {
                    return getDownloadURL(itemRef);
                });

                // Resolve all promises to get the URLs
                Promise.all(promises)
                    .then((urls) => {
                        setImages(urls.map((item) => {
                            return {name: res.items[urls.indexOf(item)].name, url: item};
                        }));
                    })
                    .catch((error) => {
                        console.log(`Error getting download URLs for folder ${folder}:`, error);
                    });
            })
            .catch((error) => {
                console.log(`Error listing items in ${folder}:`, error);
            });

    }, [folder]);

    useEffect(() => {
        if (images.length > 0) onLoad();
    }, [images, onLoad])

    useEffect(() => {
        if (images.length > numVisible) {
            setVisibleImages(images.slice(0, numVisible));
            setShowMore(true);

        } else {
            setVisibleImages(images);
            setShowMore(false);
        }
    }, [images, numVisible])

    const handleToggleVisibleImages = () => {
        if (visibleImages.length === numVisible) {
            setVisibleImages(images);
            setShowMore(false);
        } else {
            setVisibleImages(images.slice(0, numVisible));
            setShowMore(true);
        }
    };


    const handleCloseFullscreen = () => {
        setFullscreenImage(null);
    };
    const handleImageClick = (image) => {
        setFullscreenImage(image);
    }

    return (
        <div style={{width: "100%"}}>
            <div ref={containerRef} className={"image-container"}>
                {visibleImages.map((imageUrl) => {

                    return(
                    <img src={imageUrl.url} alt={folder} key={imageUrl.url} className={"grid-image"}
                         style={{cursor: "pointer"}} onClick={() => handleImageClick(imageUrl)}
                    />
                )})}
            </div>
            {(images.length > numVisible && (
                <button ref={buttonRef} onClick={handleToggleVisibleImages}>{showMore ? "Show More" : "Show Less"}</button>
            ))}
            {fullscreenImage && (
                <div className="fullscreen-image" onClick={handleCloseFullscreen}>
                    <div>
                        <img src={fullscreenImage.url} alt={folder}/>
                        <p style={{color: "whitesmoke"}}>{captions[fullscreenImage.name]}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

StorageImages.defaultProps = {
    visibleCount: 4,
    numRows: 1,
    onLoad: null,
    captions: {}
};


export default StorageImages;
