import React from 'react';

const About = () => {
    return (
        <div>
            <h2>About Me!</h2>
            <p>Hi! I'm Malea, an artist located in Burnaby, BC.</p>

            <p>As a junior artist, I am committed to continuous growth and improving my skills. While I primarily focus
                on creating full-color cartoon pieces, I'm always open to exploring different creative avenues.</p>
            <p>I tattoo in a range of styles, as seen in my <a
                href={"/flash"}>flash</a> art. If you're interested in a custom piece, please provide me with your
                information and a brief summary of your idea through the <a
                    href={"/inquire"}>inquiry</a> form on my website.</p>

            <p>Apart from full color, I also specialize in grayscale, blackout-work, and dot-work pieces. If you're
                uncertain about the exact style you want, feel free to get in touch with me, and we can work
                through it together.</p>
            <p>I strive to create a comfortable, safe and enjoyable environment for your tattoo experience.
                My priority is to ensure that you have a comfortable, safe, and enjoyable tattoo experience. I strive
                for your complete satisfaction and aim to instill confidence in your tattoo. It's important to me that
                everyone leaves with a tattoo they truly love.</p>
            <p>Currently, you can find me at Orchid Oddities Tattoo & Curio on Kingsway in Burnaby, BC. Our shop is
                known for its welcoming atmosphere and positive vibes.</p>
            <p>Visit my <a href={"/inquire"}>inquiry</a> page to get in touch with me for bookings!</p>
            <p>You can also check out my <a href={"/flash"}>flash</a> art page to see available drawings that I have
                created.</p>
        </div>
    );
};

export default About;