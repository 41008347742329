import React from 'react';
import InquiryForm from "./components/inquiryForm";

const Inquire = () => {
    return (
        <div>
            <h1>Tattoo Inquiries</h1>
            <h2>Get in contact with me</h2>
            <InquiryForm></InquiryForm>
        </div>
    );
};

export default Inquire;