import React, {useEffect, useState} from "react";
import LoadingBar from "./loadingBar";

const InquiryForm = () => {
    const [name, setName] = useState('');
    const [age, setAge] = useState("");
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const submittedTime = localStorage.getItem("timestamp");
        const expirationTime = Number(submittedTime) + 24 * 60 * 60 * 1000; // 24 hours expiration

        if (new Date().getTime() < expirationTime) {
            setSubmitted(true);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(isLoading) return;
        if (submitted) {
            alert("Inquiry form already submitted.\nWe will get back to you as soon as possible.")
            return;
        }
        setIsLoading(true);
        localStorage.setItem("timestamp", new Date().getTime().toString());

        const formData = {
            name,
            age,
            email,
            description,
        };
        fetch("https://sendinquiry-p3f6utnxya-uc.a.run.app", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                setIsLoading(false);
                if (response.ok) {
                    alert("Inquiry Sent!\nWe will get back to you as soon as possible.")
                    console.log("submission success"); // Handle the response from the Firebase function
                    setSubmitted(true);
                } else {
                    throw new Error('Error sending email'); // Throw an error for non-200 status codes
                }
            })
            .catch(error => {
                console.error(error); // Handle any errors that occur during the request
                setIsLoading(false);
                alert("Error sending inquiry. Unable to connect")
                localStorage.setItem("timestamp", "0");
                setSubmitted(false);
            });
    };

    return (
        <form id="inquiryForm" className="form-container" onSubmit={handleSubmit}>
            {isLoading && <LoadingBar/>}
            <label>
                Name:
                <input required={true} name="name" type="text" value={name}
                       onChange={(e) => setName(e.target.value)}/>
            </label>
            <br/>
            <label>
                Email:
                <input required={true} name="email" type="email" value={email}
                       onChange={(e) => setEmail(e.target.value)}/>
            </label>
            <br/>
            <label>Age:
                <label id="label-age-warning"/>
                <input required={true} name="age" value={age} type="number" min={17} max={100} onChange={(e) => {
                    if (e.target.value < 17) {
                        document.getElementById("label-age-warning").innerText = "Unfortunately we are unable to tattoo individuals under 17"
                    } else if (parseInt(e.target.value) === 17) {
                        document.getElementById("label-age-warning").innerText = "Parental consent will be needed for individuals 17 years old"
                    } else if (e.target.value > 100) {
                        document.getElementById("label-age-warning").innerText = "Please enter a valid age"
                    } else {
                        document.getElementById("label-age-warning").innerText = ""
                    }
                    setAge(e.target.value);
                }}
                />
            </label>
            <br/>
            <label>Description of tattoo (include placement, and size):
                <textarea required={true} name="description" value={description} onChange={(e) => {
                    if (e.target.value.length > 5) {
                        e.target.value = e.target.value.substring(0, 256);
                    }
                    document.getElementById("description-length").innerText = e.target.value.length + "/256"
                    setDescription(e.target.value);
                }}/>
                <small id="description-length">{description.length}/256</small>
            </label>
            <br/>
            <button type="submit">Submit</button>
        </form>
    );
};

export default InquiryForm;