import React from 'react';

const Faq = () => {
    return (
        <>
            <h1>FAQ</h1>

            <p><strong>Q: What is your hourly rate?</strong></p>
            <p>A: Hourly rate: $135, minimum charge: $85</p>

            <p><strong>Q: Where are you located?</strong></p>
            <p>A: I am located at <a rel="noopener noreferrer" href={"https://goo.gl/maps/H1pENm9CvdTKr8xB6"}
                                     target="_blank">
                Orchid Oddities Tattoo & Curio, #11-3003 Kingsway, Burnaby, BC</a></p>

            <p><strong>Q: How old do I have to be to get tattooed?</strong></p>
            <p>A: You must be 17 years old with parental consent, otherwise 18 or older</p>

            <p><strong>Q: Do you accept walk-ins?</strong></p>
            <p>A: Yes, but it's recommended to check my <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/spicypencil/">
                Instagram</a> story for walk-in availability</p>

            <p><strong>Q: Can I bring someone with me to my appointment?</strong></p>
            <p>A: Absolutely! If you're bringing more than one person, please let me know in advance as the shop may
                have limited seating</p>

            <p><strong>Q: Can I use numbing cream for my appointment?</strong></p>
            <p>A: If you'd like to use numbing cream, please let me know in advance. I offer Bactine spray, which I
                believe works the best. Please note that I don't supply numbing cream, so you would need to purchase it
                yourself and apply it 45-60 minutes before your appointment</p>

            <p><strong>Q: How do I book with you?</strong></p>
            <p>A: You can send a booking request through the <a href={"/inquire"}>inquiry</a> page on my website.</p>

            <p><strong>Q: How does the booking process work?</strong></p>
            <div style={{display: "flex", justifySelf: "center", maxWidth: "768px"}}>
                <ol style={{textAlign: "start"}}>
                    <li>Contact me through the form under the <a href={"/inquire"}>inquiry</a> page or email me
                        directly: <a href="mailto:inquiries@spicypenciltattoos.com">inquiries@spicypenciltattoos.com</a>
                    </li>
                    <li>We'll discuss your tattoo idea, including placement and size.</li>
                    <li>Once the placement and size are decided, we can book a day for your appointment. At this point,
                        I will ask for some basic booking and contact info (name, email, age).
                    </li>
                    <li>Finally, a non-refundable deposit is required to secure your appointment (the amount varies
                        depending on the tattoo). You can put down the deposit in person at the shop or via e-transfer.
                    </li>
                </ol>
            </div>

            <p><strong>Q: What happens if I can't make my appointment?</strong></p>
            <p>A: If you can't make your appointment, please send me an email or a message on Instagram at least 48
                hours before your appointment to avoid losing your deposit. If you give 48-hour notice, I will hold your
                deposit on file for up to 3 months (you will need to reschedule within this time for the deposit to be
                valid). Cancelling your appointment or not showing up will result in losing your deposit.</p>
            <small>*Deposits are non-refundable*</small>
        </>
    );
};

export default Faq;
