import React, {useEffect, useRef, useState} from 'react';

import InstaFeed from "../../instaFeed";
import LoadingBar from "./loadingBar";
import {Link} from "react-router-dom";

const InstagramFeed = ({limit}) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const instafeedContainerRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (isLoaded) return;
        // noinspection HtmlUnknownTarget
        const data = new InstaFeed({
            accessToken: "IGQVJVZAUlEc25ac3RBUExVeE5mdXVzTzFXV0tmVi1QNVZAIb3Q2QTdxVFJzOVhqOUdzUFVfWjhUNkRsRTNmVlNEYl9UY0IwU3g2akptbVVsZA0czN2ZAJUWMyb0RFVFdMTVg4RnZABblpTSmJMRWlpbDRjWQZDZD",
            template: `<a href="{{link}}" target="_blank" rel="noopener noreferrer">{{template}}</a>`,
            resolution: 'standard_resolution',
            limit: limit,

            filter: function (image) {
                if (image.tags.includes("website")) {
                    return false;
                }

                if (image.type === 'video') {
                    image.template = `<video autoplay muted disableRemotePlayback loop playsinline preload=""><source src="${image.model["media_url"]}""></video>`;
                } else {
                    image.template = `<img alt='Instagram Post' title=${image.caption} src="${image.image}"/>`
                }
                return true;
            },
            after: () => {
                instafeedContainerRef.current.addEventListener("touchstart", function () {
                    document.querySelectorAll('video').forEach((video) => video.play());
                    instafeedContainerRef.current.removeEventListener("touchstart", this);
                }, {once: true});
                setIsLoaded(true);
            },
            success: () => {
                setShowErrorMessage(false);
            },
            error: () => {
                setIsLoaded(true);
                setShowErrorMessage(true)
            },
        });
        data.run()

    }, [isLoaded, limit])
    return (<div>
        {!isLoaded && <LoadingBar/>}
        {showErrorMessage && <p>Unable to fetch Instagram posts</p>}
        <div id="instafeed" ref={instafeedContainerRef} className={"image-container"}/>
        <br/>

        <div className="insta-footer">
            <img src={"./ig_logo-512.png"} alt=""></img>
            <Link className="insta-link" target="_blank"
                  to="https://www.instagram.com/spicypencil/">@spicypencil</Link>
        </div>
    </div>)
}

InstagramFeed.defaultProps={
    limit:8
}
export default InstagramFeed;