import React from 'react';

import About from "./about";
import InstagramFeed from "./components/instagramFeed";


const Home = () => {

    const logoContainer = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "var(--backgroundPrimary)",
        borderRadius: "50%",
        border: "16px solid var(--onBackground)"
    };

    return (<>
            <h1>Spicy Pencil Tattoos</h1>
            <br/>
            <div style={logoContainer} className={"hover-jiggle  page-enter logo-fade "}>
                <img src={"/logo_anim-512.gif"} className={""} alt="Logo" style={{aspectRatio: 1, maxHeight: "256px"}}
                     onLoad={(e) => {e.target.parentElement.style.opacity = 1}}/>
            </div>
            <br/>
            <br/>
            <InstagramFeed/>
            <br/>
            <About/>
        </>
    );
};

export default Home;
