import React from 'react';

const Appointments = () => {
    return (
        <div>
            <h1>Preparing for Your Tattoo</h1>
            <p>Bring a <u>government-issued</u> photo ID (this is a requirement for everyone).</p>
            <p>Depending on the length of your session, it's a good idea to bring a snack and a drink with you (we also
                have drinks and snacks available at the shop). Make sure you eat something before your appointment and
                stay well hydrated.</p>
            <p>Wear tattoo-appropriate clothing that allows easy access to the tattoo area.</p>
            <h2>Please arrive on time for your appointment!</h2>
            <p>If you anticipate being late, please send me a message in advance so I can adjust my schedule if
                possible. If you do not inform me and are more than 15 minutes late, your deposit will be forfeited and
                you will need to reschedule your appointment with a new deposit.</p>
            <p>I <u>cannot</u> tattoo you if the area is sunburnt or if the skin is damaged in any way (e.g., burns,
                cuts, open wounds, etc.). If you're unsure if this applies to you, send me a message, and we can figure
                it out together!</p>
            <p><u>No one</u> under any influence of drugs or alcohol will be permitted to be tattooed. Please refrain from consuming
                them before coming in!</p>
            <p>If you have any further questions about preparing for your appointment, please get in contact with me
                through the <a href={"/inquire"}>inquiry</a> page or email me directly at: <a
                    href="mailto: inquiries@spicypenciltattoos.com">inquiries@spicypenciltattoos.com</a></p>
        </div>

    );
};

export default Appointments;