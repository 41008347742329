import React from 'react';

const LoadingBar = () => {
    const loadingBarStyles = {
        height: '6px',
        width: '100%',
        position: 'fixed',
        top: '64px',
        left: '0',
        zIndex: '9999',
    };

    const backgroundStyles = {
        backgroundColor: 'var(--onBackground)',
        height: '100%',
        width: '100%',
    };

    const pulseStyles = {
        backgroundColor: 'var(--backgroundSecondary)', // Replace with your desired background color for the pulsing loading bar
        height: '100%',
        marginLeft: "25%",
        width: '50%',
        animation: 'pulse 2s linear infinite',
    };

    const pulseKeyframes = `
    @keyframes pulse {
      0% {
        transform: translateX(-100%);
      }
      50% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  `;

    return (
        <>
            <style>{pulseKeyframes}</style>
            <div style={loadingBarStyles}>
                <div style={backgroundStyles}>
                    <div style={pulseStyles}></div>
                </div>
            </div>
        </>
    );
};

export default LoadingBar;
