import './style/App.css';
import React, {useEffect, useRef, useState} from 'react';
import {Routes, Route, Link, useLocation, BrowserRouter, useNavigate} from 'react-router-dom';

import {CSSTransition, TransitionGroup} from 'react-transition-group';


import Particles from "react-tsparticles";
import {loadSnowPreset} from "tsparticles-preset-snow";
import Home from "./pages/home";
import About from "./pages/about";
import Inquire from "./pages/inquire";
import Faq from "./pages/faq";
import Appointments from "./pages/appointment";
import Aftercare from "./pages/aftercare";
import Flash from "./pages/flash";
import Logo from "./pages/components/logo";


const lastUpdated = "July 2023";

const particleOptions = {
    background: {
        color: "#0000000"
    },
    detectRetina: true,
    particles: {
        color: {
            value: "#000000",
        },

        number: {value: 10, density: {enable: true, value_area: 250}},

        move: {
            direction: "none",
            enable: true,

            random: true,
            speed: 1.5,

        },
    },
    preset: "snow",
};


const NotFound = () => {
    return (
        <div>
            <h1>Page Not Found</h1>
            <h2>Error - 404</h2>
            <br/>
            <Link to="/">Go Home</Link>
        </div>
    );
};

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const hamburgerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (isOpen && !hamburgerRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);
    return (
        <nav>
            <div className="navbar-container ">
                <Link to="/" className="nav-logo hover-jiggle">
                    <Logo/>
                </Link>

                <div className={`nav-menu`}>
                    <ul>
                        <li>
                            <Link to="/home" className="nav-link">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/flash" className="nav-link">
                                Flash
                            </Link>
                        </li>
                        <li>
                            <Link to="/inquire" className="nav-link">
                                Inquire
                            </Link>
                        </li>

                        <div className={`nav-menu nav-menu-collapse ${isOpen ? 'open' : ''}`}>
                            <li>
                                <Link to="/appointments" className="nav-link">
                                    Appointments
                                </Link>
                            </li>

                            <li>
                                <Link to="/aftercare" className="nav-link">
                                    Aftercare
                                </Link>
                            </li>

                            <li>
                                <Link to="/faq" className="nav-link">
                                    Faq
                                </Link>
                            </li>
                        </div>
                    </ul>
                </div>

                <div className="hamburger" ref={hamburgerRef} onClick={() => setIsOpen(!isOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </nav>
    );
}

const AnimatedRoutes = () => {
    const location = useLocation();
    return (
        <TransitionGroup>
            <CSSTransition key={location.key} timeout={1000} unmountOnExit classNames="page">
                <main>
                    <Routes location={location}>
                        <Route index element={<Home/>}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/inquire" element={<Inquire/>}/>
                        <Route path="/contact" element={<Inquire/>}/>
                        <Route path="/faq" element={<Faq/>}/>
                        <Route path="/appointments" element={<Appointments/>}/>
                        <Route path="/aftercare" element={<Aftercare/>}/>
                        <Route path="/flash" element={<Flash/>}/>

                        <Route path="/about" element={<About/>}/>

                        <Route path="*" element={<NotFound/>}/>
                    </Routes></main>
            </CSSTransition>
        </TransitionGroup>);
}
const ScrollToTop = () => {
    useNavigate()
    window.scrollTo(0, 0);
};

const App = () => {
    return (<BrowserRouter>
            <ScrollToTop/>
            <Particles className="particle-background" init={loadSnowPreset} options={particleOptions}/>
            <div>
                <NavBar/>
                <AnimatedRoutes/>

                <footer>
                    <div className="footer-container">
                        <small>SpicyPencil Tattoos</small>
                        <div className="footer-divider"/>

                        <img src={"/tt_logo-512.png"} alt=""></img>
                        <Link className="footer-link" target="_blank"
                              to="https://www.tiktok.com/@spicypencil">@spicypencil</Link>
                        <div className="footer-divider"/>

                        <img src={"/ig_logo-512.png"} alt=""></img>
                        <Link className="footer-link" target="_blank"
                              to="https://www.instagram.com/spicypencil/">@spicypencil</Link>
                        <div className="footer-divider"/>

                        <img src={"/fb_logo-512.png"} alt=""></img>
                        <Link className="footer-link" target="_blank"
                              to="https://www.facebook.com/profile.php?id=100091289686922">spicypencil</Link>
                        <div className="footer-divider"/>

                        <small>Vancouver BC, Canada</small>
                        <div className="footer-divider"/>

                        <small>{lastUpdated}</small>
                    </div>
                </footer>
            </div>
        </BrowserRouter>
    );
};

export default App;