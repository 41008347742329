import React, { useEffect, useState } from 'react';
import StorageImages from './components/storageImages';
import LoadingBar from './components/loadingBar';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const Flash = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [captions, setCaptions] = useState({});

    useEffect(() => {
        getDoc(doc(db, 'data', 'flashCaptions'))
            .then((result) => {
                if (result.data()) {
                    setCaptions(result.data());
                } else {
                    console.log("Can't fetch captions");
                }
            })
            .catch((error) => {
                console.log('Error fetching captions:', error);
            });
    }, []);

    return (
        <>
            {!isLoaded && <LoadingBar />}
            <h1>Available Flash</h1>
            <br />
            <h2>
                Fill out my inquiry form or send a message on Instagram if you would like to book any of these or if you have any
                questions.
            </h2>
            <p>
                For all flash inquiries, please include the placement of the tattoo and a rough size (some pieces will have to be
                of a certain size).
            </p>
            <br />

            <h2 className="with-subtext">D20 Dice Roll</h2>
            <small>
                Test your luck and roll the dice for your design! Whatever you roll, you may pick that design or any before! (e.g.,
                if you roll a 12, you can choose any design 1-12).
            </small>
            <small>All D20 pieces available 3-4' (Simpsons available up to 4.5').</small>
            <StorageImages folder="promo" captions={captions} numRows={1} onLoad={() => setIsLoaded(true)} />
            <br />

            <h2 className="small-bottom-margin">Promo Flash</h2>
            <small>These are my discounted flash sheets as well as a fun customizable Tamagotchi flash.</small>
            <StorageImages folder="promoFlash" captions={captions} numRows={1} onLoad={() => setIsLoaded(true)} />
            <br />

            <h2 className="with-subtext">Flat Rate Flash</h2>
            <small>These are all pre-priced pieces, and some may be of a set size. Inquire for more info.</small>
            <StorageImages folder="setPriceFlash" captions={captions} numRows={1} onLoad={() => setIsLoaded(true)} />
            <br />

            <h2 className="with-subtext">Hourly Rate Flash</h2>
            <small>All of these pieces will be charged at my hourly rate of $135.00/hr.</small>
            <StorageImages folder="hourlyFlash" captions={captions} numRows={1} onLoad={() => setIsLoaded(true)} />
            <br />
        </>
    );
};

export default Flash;
