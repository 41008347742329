import React from 'react';

const Aftercare = () => {
    return (
        <>
            <h1>Aftercare Instructions</h1>
            <p>You may notice blood or ink pooling up under the bandage... <u>THIS IS NORMAL!</u></p>
            <p>If it seems excessive or you are concerned about it, please send me an email with a photo or visit the
                shop so we can assess it.</p>

            <h2 style={{marginBottom: 0}}>Things to Avoid</h2>
            <small>(Until the tattoo is fully healed)</small>
            <div style={{display: "flex", justifySelf: "center", maxWidth: "768px"}}>
                <ol style={{textAlign: "start"}}>
                    <li>Avoid direct sunlight.</li>
                    <li>Avoid swimming or soaking the tattoo in water.</li>
                    <li>Avoid physical activity or excessive sweating in the tattooed area.</li>
                </ol>
            </div>
            <p>Once it's safe to remove the bandage (typically after 3-4 days, you will be informed of the specific
                duration), <u>gently</u> wash the tattooed area with a <u>mild unscented</u> soap and <u>pat</u> (not
                rub!) it dry with a <u>clean</u> paper towel.</p>

            <p>Over the next 4-7 days the tattoo will begin to dry out and heal. At this point, if the tattoo is dry and flaky,
                you can apply a thin layer of unscented lotion or tattoo ointment 1-2 times per day as needed.</p>

            <h2 style={{marginBottom: 0}}>What to Do if My Bandage Comes Off?</h2>
            <small>Don't panic!</small>
            <p><u>Do not</u> reapply the dirty bandage. Visit the shop, and we will provide you with a new one. If this
                is not possible, carefully monitor the tattooed area. Keep the tattooed area dry and clean until the skin begins
                to flake. Only <u>after</u> the skin starts flaking, it's okay to apply moisturizer or ointment as needed.</p>

            <h2>Do Not Scratch or Pick at Your Tattoo!</h2>
            <p>It is normal for a tattoo to itch or feel irritated. A safe way to alleviate this is
                to <u>lightly</u> slap or apply pressure to the area with your hand. It is <u>important</u> to avoid
                scratching or tearing the skin, as it can affect the healing process of your tattoo.</p>

            <h2>Once Your Tattoo Has Healed</h2>
            <p>Sunlight can cause the ink pigments in your tattoo to break down over time, leading to fading. Remember
                to use sunscreen (SPF 30 or above) to help minimize fade in your tattoo over time.</p>
            <p>At this point if you notice any areas that need to be touched up you can contact me to book for a touch-up
            *all touch-ups are free of charge if within 3 months of getting the tattoo from me</p>

            <h3>Always Keep Fresh/Unhealed Tattoos Out of the Sun!</h3>

            <p>If you have any other questions or concerns, send me a message on the <a
                href={"/inquire"}>inquiry</a> page of my site or email me directly at <a
                href="mailto:inquiries@spicypenciltattoos.com">inquiries@spicypenciltattoos.com</a> or feel free to
                visit us at the shop!</p>

            <h2>Happy Healing!</h2>
        </>
    );
};

export default Aftercare;
