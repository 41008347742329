import React from 'react';

const Logo = () => {
    const logoStyle = {
        position:"absolute",
        height: "80%",
        aspectRatio: 1,
    };

    const logoContainer = {
        height: '48px',
        aspectRatio: 1,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        backgroundColor: "var(--onBackground)",
        borderRadius: "50%",
        border: "4px solid var(--backgroundPrimary)"
    };


    return (
        <>
            <div style={logoContainer}>
                <img src={"/logo_plain-192.png"} alt="Logo" style={logoStyle}/>
            </div>
        </>
    );
};
export default Logo;
